@import url(https://rsms.me/inter/inter.css);
body {
  margin: 0;
  font-family: 'Inter', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

@supports (font-variation-settings: normal) {
  body { font-family: 'Inter var', sans-serif; }
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

* {
  box-sizing: border-box;
  position: relative;
}

/* Special rule for Atlaskit portal */
.atlaskit-portal-container * {
  position: unset;
}

body,
#root {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}

a,
a:hover,
a:focus {
  text-decoration: inherit;
  color: inherit;
}

ul {
  list-style: none;
  padding: 0;
}

h1 {
  font-size: 22px;
  font-weight: 400;
  line-height: 27px;
  color: #222;
}

h3 {
  font-size: 18px;
  line-height: 24px;
  color: #222;
  font-weight: 400;
}

h4 {
  font-size: 14px;
  color: #222;
  line-height: 18px;
  font-weight: 500;
}

